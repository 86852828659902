<template>
  <b-list-group-item
    button
    v-bind:class="{ disabled: knocking }"
    @click.prevent="knock()"
    class="d-flex justify-content-between align-items-center"
  >
    {{ name }}
    <b-spinner v-if="knocking" variant="primary" type="grow" small></b-spinner>
  </b-list-group-item>
</template>

<script>
import * as api from './../services/doors-api';

export default {
  name: 'Doors',
  props: ['id', 'name'],
  data() {
    return {
      knocking: false
    };
  },

  methods: {
    async knock() {
      this.knocking = true;
      try {
        await Promise.all([api.knock(this.id), new Promise(resolve => setTimeout(resolve, 10000))]);
      } finally {
        this.knocking = false;
      }
    }
  }
};
</script>
