import axios from 'axios';

export async function getNearDoors(locationCode) {
  const response = await axios.get(`api/doors?location=${encodeURIComponent(locationCode)}`);

  return response.data;
}

export async function knock(id) {
  await axios.post(`api/doors/${id}`);
}
