<template>
  <div class="doors">
    <b-alert v-model="showError" variant="danger" class="position-fixed fixed-bottom m-0 rounded-0" dismissible>
      {{ error }}
    </b-alert>
    <b-list-group>
      <door v-for="door in doors" :key="door.id" :id="door.id" :name="door.name"></door>
    </b-list-group>
  </div>
</template>

<script>
import * as api from './../services/doors-api';
import { debounceTime, distinctUntilKeyChanged, mergeMap } from 'rxjs/operators';
import Door from './Door.vue';
import locations from './../services/geo';
import { Subscription } from 'rxjs';

export default {
  name: 'Doors',
  components: {
    Door
  },
  data() {
    return {
      subscription: Subscription.EMPTY,
      error: undefined,
      doors: []
    };
  },
  computed: {
    showError: {
      get() {
        return !!this.error;
      },
      set() {}
    }
  },
  mounted() {
    this.subscription = locations
      .pipe(
        distinctUntilKeyChanged('location', (a, b) => a.substring(0, 11) === b.substring(0, 11)),
        debounceTime(2000),
        mergeMap(p => api.getNearDoors(p.location))
      )
      .subscribe(
        doors => (this.doors = doors),
        error => {
          this.doors = [];
          this.error = error.message;
        }
      );
  },
  destroyed() {
    this.subscription.unsubscribe();
  }
};
</script>
