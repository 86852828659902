import { Observable } from 'rxjs';
import OpenLocationCode from 'open-location-code-typescript';
import { share } from 'rxjs/operators';

export default new Observable(subscriber => {
  const watchId = navigator.geolocation.watchPosition(
    geo =>
      subscriber.next({
        timestamp: new Date(geo.timestamp),
        location: OpenLocationCode.encode(
          geo.coords.latitude,
          geo.coords.longitude,
          OpenLocationCode.CODE_PRECISION_EXTRA
        ),
        accuracy: geo.coords.accuracy
      }),
    error => {
      navigator.geolocation.clearWatch(watchId);
      subscriber.error(error);
    },
    {
      enableHighAccuracy: true
    }
  );

  return () => {
    navigator.geolocation.clearWatch(watchId);
  };
}).pipe(share());
