<template>
  <div id="app">
    <b-jumbotron header="Silvana" lead="Knock to open the door"> </b-jumbotron>
    <b-container>
      <b-row>
        <b-col>
          <doors></doors>
        </b-col>
      </b-row>
      <b-row class="mt-5 font-weight-lighter">
        <b-col>
          <location-details></location-details>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Doors from './components/Doors.vue';
import LocationDetails from './components/LocationDetails.vue';
export default {
  name: 'App',
  components: {
    Doors,
    LocationDetails
  }
};
</script>
