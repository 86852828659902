<template>
  <div class="location-details">
    <b-button v-b-toggle.details variant="outline-light" :pressed.sync="debug">
      {{ debug ? 'hide' : 'show' }} locations</b-button
    >
    <b-collapse id="details" class="mt-2">
      <b-table :items="items" :fields="fields">
        <template #cell(location)="data">
          <a target="_blank" :href="`https://www.google.nl/maps/place/${encodeURIComponent(data.value)}`">{{
            data.value
          }}</a>
        </template>
      </b-table>
    </b-collapse>
  </div>
</template>

<script>
import locations from './../services/geo';
import { Subscription } from 'rxjs';

export default {
  name: 'LocationDetails',
  data() {
    return {
      debug: false,
      subscription: Subscription.EMPTY,
      items: [],
      fields: [
        {
          key: 'timestamp',
          formatter: value => value.toLocaleTimeString()
        },
        {
          key: 'location',
          label: 'Location'
        },
        {
          key: 'accuracy',
          formatter: value => `${value} m`
        }
      ]
    };
  },
  mounted() {
    this.subscription = locations.subscribe(p => this.items.unshift(p));
  },
  destroyed() {
    this.subscription.unsubscribe();
  }
};
</script>
